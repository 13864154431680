import { Signal, signal, untracked } from "@preact/signals";
import { Fragment, h } from "preact";
import { breakLines } from "../../../logic/util/util.ts";
import { Icon } from "../../components/Icon/icon.tsx" with { type: 'macro' };
import "./toasts.scss";

interface Toast {
    subtitle?: string;
    title: string;
    message: string | any;
    type: "success" | "error" | "warning" | "info" | "notification";
    timeout?: number;
    
    closed?: Signal<boolean>;
    id?: number;
}

const toasts = signal<Toast[]>([]);
export const pushToast = (t:Toast) => untracked(() => {
    t.id = toastId++;
    t.closed = signal(false);
    toasts.value = [...toasts.value, t];
    if (t.timeout) setTimeout(() => popToast(t), t.timeout);
});
export const popToast = (t:Toast) => untracked(() => {
    if (t.closed) t.closed.value = true;
    setTimeout(() => {
        toasts.value = toasts.value.filter(t2 => t2.id != t.id);
    }, 300);
});
(window as any).pushToast = pushToast;

export function pushToastFromError(e:any) {
    if (e instanceof ProgressEvent && e.target && "error" in e.target) {
        return pushToastFromError(e.target.error);
    }
    let message = [] as any[];
    if (e.serverStack)
        message.push(<>
            <h5>Server stack:</h5>
            <pre>{e.serverStack}</pre>
        </>);
    if (e.stack)
        message.push(<>
            <h5>Client stack:</h5>
            <pre>{e.stack}</pre>
        </>);
    if (message.length) message = [<details><summary>Technical Details</summary>{message}</details>];
    pushToast({
        subtitle: (e.name ?? "").replace(/_/g, " "), 
        title: e.message ?? "Error", 
        message: message.length ? message : e.toString(), type: "error", 
        timeout: 10000
    });
}


window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').');
    pushToastFromError(event.reason);
})

let icons = {
    success: Icon('check'),
    error: Icon('close'),
    warning: Icon('exclamation'),
    info: Icon('info_i'),
    notification: ""
}


let toastId = 0;
export const RenderToasts = () => 
    <div class="cToasts">{toasts.value.map(t =>
        <div class='toast' data-type={t.type} data-closed={t.closed}>
            <div class='toastHeader'>
                {icons[t.type]}
            </div>
            <div class='toastBody'>
                <h2 class='upperSubtitle'>{t.subtitle}</h2>
                <h1>{t.title}</h1>
                <p>{typeof t.message == 'string' 
                        ? breakLines(t.message)
                        : t.message}</p>
            </div>
        </div>
    )}</div>