import { pushToast } from "../ui/elements/toasts/toasts.tsx";
import { C2WMessage, W2CMessage } from "../worker/types";

let workerResolve: (value: SharedWorker) => void;
let worker = new Promise<SharedWorker>((a,r) => workerResolve = a);

export async function bringUpSharedWorker() {
    let workerUrl = new URL("../worker/index.ts", import.meta.url);
    // it's a stupid solution, but it's a _working_ stupid solution.
    // https://github.com/parcel-bundler/parcel/issues/9931
    if (process.env.NODE_ENV == "development") {
        // @ts-ignore
        let workerContent = await import("bundle-text:../worker/index.ts");
        let hash = await crypto.subtle.digest("SHA-1", (new TextEncoder()).encode(workerContent));
        let hex = [...new Uint8Array(hash)].map(a => a.toString(16).padStart(2,'0')).join('')
        workerUrl.search = "?" + hex;
    }
    console.log(workerUrl.toString())
    let worker = new SharedWorker(workerUrl, { 
        type: "module",
        name: "Systech CRM Shared Worker  (" + workerUrl + ")"
    });
    let lastHeard = Date.now();
    worker.port.onmessage = (e) =>{
        lastHeard = Date.now();
        let data: W2CMessage = e.data;
        if (data.type == "ping") 
            sendSharedWorkerMessage({ type: "pong" });
        console.log(e);
    };
    setInterval(() => {
        if ((Date.now() - lastHeard) > 5000) {
            pushToast({
                type: "error",
                title: "Systech CRM has encountered an error",
                subtitle: "Shared worker timeout",
                message: "Please let someone know! (in the mean time try retarting the application)",
                timeout: 1000,
            })
        }
    },1000)
    worker.port.start();
    workerResolve(worker);
    console.log({worker});

}
async function sendSharedWorkerMessage(data: C2WMessage) {
    (await worker).port.postMessage(data)
}
